export const menuData = [
    {
        title: 'About Us',
        link: '/#about'
    },
    {
        title: 'Services',
        link: '/#services'
    },
    {
        title: 'Work',
        link: '/#work'
    },
    // {
    //     title: 'Team',
    //     link: '/#team'
    // }
]
