import SMM from '../assets/features/social media marketing.svg'
import INM from '../assets/features/influencer marketing.svg'
import BSP from '../assets/features/branding strategic planner.svg'
import CNC from '../assets/features/content creation.svg'
import OOA from '../assets/features/online offline activation.svg'
import WSO from '../assets/features/website seo optimization.svg'

export const featureData = [
    {
        icon: SMM,
        name: 'Social Media Marketing',
        description: 'Helping you maximizing the digital amplification through social media to get your objectives done, we can increase your online conversion rates, develop your brand awareness, improve your audience engagement, and influence their purchase decisions.'
    },
    {
        icon: INM,
        name: 'Influencer Marketing',
        description: 'We believe Personification in Communication is the best way to help audience more understand Brand message and value. We provide you the most suitable Key Opinion Leader that spread throughout Indonesia in almost all interest and platform that fit to your business objective.'
    },
    {
        icon: BSP,
        name: 'Branding & Strategic Planning',
        description: 'We help you to reach your goals based on in-depth research analysis and strategic planning. Let us guide you to create strong branding to enhance credibility and the ability to stand out in the market.'
    },
    {
        icon: CNC,
        name: 'Content Creation',
        description: 'We make your content speak from raw to ready-to-post and let them dominate the minds of your audience. We can help deliver the key message, increase brand visibility, improve brand recognition, create audience loyalty and trust, and position your business as an expert in your industry.'
    },
    {
        icon: OOA,
        name: 'Online & Offline Activation',
        description: 'We accommodate you to find the most appropriate way to amplify your offline activation and online strategy. We can help to increase your online and offline presence, develop your campaign strategy into concrete activation, and generate leads also potential footfall for your business.'
    },
    {
        icon: WSO,
        name: 'Website & SEO Optimization',
        description: 'We ensure that your site to have a better user experience with the most compelling content. Maximizing the UI & UX combination to create an outstanding journey for the audience to get closer to your brand.'
    },
]
