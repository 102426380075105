import React from 'react'
import styled from 'styled-components'
import { BREAK_POINT, Color } from '../../styles/variables'
import { HeadingTitle } from '../HeadingTitle'
import LazyLoad from 'react-lazyload'
import { teamData } from '../../data/teamData'

const TeamContainer = styled.section`
    .container {
        padding: 5rem 0;

        .heading-wrapper {
            text-align: center;
        }

        .team-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            margin: 5rem 0;
            width: 100%;
        }
    }
`

const TeamItemWrapper = styled.div`
    width: 33.33%;
    position: relative;
    cursor: pointer;
    color: #fff;

    @media only screen and (max-width: ${BREAK_POINT}) {
        width: 100%;
    }

    img {
        width: 100%;
        height: auto;
        object-fit: cover;
        transition: .3s;
        padding: 1rem;
        opacity: 1;
    }

    img.idle {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
    }

    .title-wrapper {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 0 2.5rem 4rem;
        z-index: 1;

        h4 {
            font-weight: 800;
            margin-bottom: 0.25rem;
        }

        p {
            width: 70%;
            height: 1.25em;
            overflow: visible;
        }
    }

    &:hover, &:active {
        img.idle {
            opacity: 0;
        }
    }
`

const TeamSection = () => {
    return (
        <TeamContainer>
            <div className="container">
                <div className="heading-wrapper">
                    <HeadingTitle color={Color.primary}>
                        <span>Meet</span>The Team
                    </HeadingTitle>
                </div>
                <div className="team-wrapper">
                    {
                        teamData.map((item, index) => (
                            <TeamItemWrapper key={index}>
                                <LazyLoad height={325} offset={200} once>
                                    <img src={item.imgHover} alt={item.name}/>
                                </LazyLoad>
                                <LazyLoad height={325} offset={200} once>
                                    <img className="idle" src={item.imgIdle} alt={item.name}/>
                                </LazyLoad>
                                    <div className="title-wrapper">
                                        <h4>{item.name}</h4>
                                        <p className="small">{item.position}</p>
                                    </div>
                            </TeamItemWrapper>
                        ))
                    }
                </div>
            </div>
        </TeamContainer>
    )
}

export default TeamSection
