import styled from 'styled-components'
import { OutlineHeading } from '../styles/helperStyle'
import { Color } from '../styles/variables'

export const HeadingTitle = styled.h2`
    font-weight: 900;
    line-height: 1.25;
    text-transform: uppercase;
    color: ${ Color.primary };
    position: relative;

    span {
        display: block;
        ${ OutlineHeading(Color.primary) }
    }

    &::after {
        content: '.';
        width: 0px;
        overflow: visible;
        position: absolute;
    }
`
