import React from 'react'
import styled from 'styled-components'
import { BREAK_POINT, Color } from '../../styles/variables'
import { HeadingTitle } from '../HeadingTitle'
import logoImage from '../../assets/logo-color.svg'

const AboutContainer = styled.section`
    .container {
        text-align: center;
        padding: 5rem 0;

        p.lead {
            margin: 3rem auto;
        }

        .achieves-container {
            margin: 5rem auto -1rem;

            p.lead {
                color: ${ Color.primary };
                width: 34rem;
                max-width: 100%;
                margin: 0 auto -2rem;
            }

            @media only screen and (max-width: ${BREAK_POINT}) {
                margin-bottom: 4rem;

                p.lead {
                    margin-bottom: 2rem !important;
                }
            }

            .achieves-wrapper {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: center;

                @media only screen and (max-width: ${BREAK_POINT}) {
                    display: block;

                    .achieves-item {
                        margin: 3rem 0 !important;
                    }

                }

                .achieves-item {
                    width: 25rem;
                    max-width: 100%;
                    margin: 6.5rem 1rem;

                    h1 {
                        color: ${ Color.primary };
                        font-weight: 800;
                        margin-bottom: 0.5rem;
                    }
                    p {
                        color: ${ Color.grey };
                        font-weight: 600;
                    }
                }

                .achieves-logo {
                    width: 40rem;
                    max-width: 100%;
                    margin: -3rem;

                    img {
                        height: auto;
                        width: 100%;
                        object-fit: cover;
                    }

                    @media only screen and (max-width: ${BREAK_POINT}) {
                        order: 0;
                        margin: -3rem 0;
                    }
                }
            }
        }

        h3 {
            color: ${ Color.primary };
            font-weight: 800;
            line-height: 1.5;
        }
    }
`

const AboutSection = () => {
    return (
        <AboutContainer>
            <div className="container">
                <HeadingTitle>
                    <span>Get to</span>Know us
                </HeadingTitle>
                <p className="lead">
                    Established in 2016, Virallo has been trusted by various brands to help to connect them with the audience and offer solutions to reach their goals. We do it through high-quality campaigns, collaborate with our influencers, and utilize digital platforms to create the most suitable strategy for you.
                </p>
                <div className="achieves-container">
                    <p className="lead">
                        A creative and digital marketing platform that deliver the best possible return for our clients
                    </p>
                    <div className="achieves-wrapper">
                        <div>
                            <div className="achieves-item">
                                <h1>5.000+</h1>
                                <p>Influencers Connected</p>
                            </div>
                            <div className="achieves-item">
                                <h1>40 Million+</h1>
                                <p>Impression</p>
                            </div>
                        </div>
                        <div className="achieves-logo">
                            <img src={logoImage} alt="Virallo"/>
                        </div>
                        <div>
                            <div className="achieves-item">
                                <h1>171+</h1>
                                <p>Brands Assisted</p>
                            </div>
                            <div className="achieves-item">
                                <h1>10.000+</h1>
                                <p>Contents Produced</p>
                            </div>
                        </div>
                    </div>
                </div>
                <h3>
                    With Virallo,<br/>
                    Anyone Can Connect<br/>
                    With Everyone.
                </h3>
            </div>
        </AboutContainer>
    )
}

export default AboutSection
