import React, { useState } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from 'react-responsive-carousel'
import styled from 'styled-components'
import { HeadingTitle } from '../HeadingTitle'
import { portfolioData } from '../../data/portfolioData'
import { BREAK_POINT, Color } from '../../styles/variables'
import {BsArrowRight} from 'react-icons/bs'
import {GoPrimitiveDot} from 'react-icons/go'
import {FaAngleDown} from 'react-icons/fa'
import { maxWidthContainer } from '../Navbar'

const PortfolioContainer = styled.section`
    padding: 5rem 0 10rem;

    @media only screen and (max-width: ${BREAK_POINT}) {
        overflow-y: visible;
    }

    .container {
        min-height: fit-content;
        padding-bottom: 5rem;

        .heading-wrapper {
            text-align: center;
        }
    }

    .content-container {
        width: ${maxWidthContainer};
        max-width: 100%;
        overflow: visible;
        margin: 0 auto;

        .carousel.carousel-slider {
            overflow: visible;

            .slider-wrapper {
                overflow: visible;
            }

            .control-dots {
                margin-bottom: -6rem;
                padding: 0 2rem;

                .dot {
                    height: 14px;
                    width: 14px;
                    background: ${Color.accent};
                    border-radius: 0;
                    box-shadow: none;
                    opacity: 1;
                    transition: .3s;

                    &.selected {
                        transform: scale(1.25);
                        background: ${Color.primary};
                    }
                }
            }
        }
    }

`

const PortfolioThumbs = styled.div`
    display: flex;
    flex-wrap: nowrap;
    cursor: pointer;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    /* @media only screen and (max-width: ${BREAK_POINT}) {
        flex-direction: column;
        align-items: center;
    } */

    .thumbnail {
        height: 30rem;
        width: 28rem;
        border: 2px solid ${ Color.primary };
        position: relative;
        overflow: visible;
        transition: .3s;

        @media only screen and (max-width: ${BREAK_POINT}) {
            height: 20rem;
            width: 18rem;

            img {
                height: 18rem !important;
                width: 18rem !important;
            }
        }

        img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            margin-left: -1rem;
            height: 28rem;
            width: 28rem;
            object-fit: contain;
            transition: .3s;
        }    
    }

    .title {
        width: fit-content;
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
        /* align-self: flex-end; */
        font-weight: 800;
        color: ${Color.primary};
        text-align: left;
        padding: 3rem 1rem;
        transition: .3s;

        @media only screen and (max-width: ${BREAK_POINT}) {
            /* width: 100%; */
            align-self: center;
            text-align: center;
        }

        h5::before {
            content: '';
            height: 14px;
            width: 14px;
            background-color: ${Color.accent};
            margin-right: 0.5rem;
            display: inline-flex;
            align-self: center;
        }

        h2 {
            text-transform: uppercase;
            
            @media only screen and (max-width: ${BREAK_POINT}) {
                font-size: 2rem;
            }
        }
    }


    &:hover {
        .thumbnail {
            border-width: 4px;
            img {
                transform: translate(-52%, -52%);
            }
        }
        .title {
            transform: translate(-0.5rem, -0.5rem);
        }
    }

`

const PortfolioContent = styled.div`
    width: 100%;
    background-color: ${Color.primary};
    color: #fff;
    height: 48rem;
    position: absolute;
    top: -3rem;
    opacity: ${ ({isSelected}) => (isSelected ? 1 : 0) };
    z-index: ${ ({isSelected}) => (isSelected ? 1 : -1) };
    transform: ${ ({isSelected}) => (isSelected ? 'translateY(0)' : 'translateY(100%)') };
    transition: .3s ease-in-out;

    @media only screen and (max-width: ${BREAK_POINT}) {
        height: ${ ({isSelected}) => (isSelected ? '60rem' : '20rem') };
    }

    .btn-container {
        width: ${maxWidthContainer};
        max-width: 100%;
        padding-right: 2rem;
        text-align: right;
        position: absolute;
        font-size: 2rem;
        cursor: pointer;
        top: 2rem;
        left: 50%;
        transform: translateX(-50%);
        z-index: 100;
        display: ${ ({isSelected}) => (isSelected ? 'block' : 'none') }
    }

    .container {
        display: flex;
        align-items: center;
        /* padding: 3rem 0; */
        flex-wrap: wrap;

        .description {
            flex: 1 1 auto;
            order: 0;
            margin-top: 1rem;

            @media only screen and (max-width: ${BREAK_POINT}) {
                order: 1;
            }

            img {
                margin-bottom: 1rem;
            }

            .services, .result {
                padding: 2rem 1rem;
                border-top: 1px solid rgba(255, 255, 255, 0.6);
                
                h5 {
                    text-transform: uppercase;
                    font-style: italic;
                    font-weight: 700;
                    margin-bottom: 1rem;
                }
                
                div {
                    display: flex;
                    align-items: center;
                    margin: 0.5rem 0;
                    
                    svg {
                        font-size: 2rem;
                        margin: 0 2rem;
                    }
                }
            }

            .services {
                div {
                    line-height: 2.25;

                    svg {
                        margin: 0 1rem 0 0;
                        font-size: 1.5rem;
                    }

                    span {
                        font-weight: 700;
                    }
                }
            }
            .result {
                div .result-name {
                    width: 30%;
                }
                div span {
                    width: auto;
                }
            }

        }

        .image {
            order: 1;
            overflow: visible;
            position: relative;
            height: 20.5rem;
            width: 20.5rem;
            border-radius: 1000px;
            border: 4px solid #fff;
            margin: 4rem;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 200%;

                @media only screen and (max-width: ${BREAK_POINT}) {
                    max-width: 80vw;
                }
            }

            @media only screen and (max-width: ${BREAK_POINT}) {
                order: 0;
                height: 15rem;
                width: 15rem;
                margin: 2rem auto;
            }
        }
    }
`

const PortfoliosSection = () => {
    const [selectedItem, setSelectedItem] = useState(null)

    const toggleSelected = (item) => {
        setSelectedItem(item)
    }

    return (
        <PortfolioContainer>
            <div className="container">
                <div className="heading-wrapper">
                    <HeadingTitle>
                        <span>Our</span>Project
                    </HeadingTitle>
                </div>
            </div>
            <div style={{ position: 'relative' }}>
                <div className="content-container">
                    <Carousel
                        className="hide-in-break"
                        infiniteLoop
                        autoPlay={selectedItem === null}
                        emulateTouch
                        centerMode
                        showStatus={false}
                        showArrows={false}
                        showThumbs={false}
                        centerSlidePercentage={50}
                        onChange={(index) => (
                            selectedItem !== null && (toggleSelected(portfolioData[index]))
                        )}
                    >
                        {
                            portfolioData.map((item, index) => (
                                <PortfolioThumbs key={index} onClick={ () => toggleSelected(item) }>
                                    <div className="thumbnail">
                                        <img src={item.thumbnail} alt={item.name} />
                                    </div>
                                    <div className="title">
                                        <h5>0{ index + 1 }</h5>
                                        <h2>{ item.name }</h2>
                                    </div>
                                </PortfolioThumbs>
                            ))
                        }
                    </Carousel>
                    <Carousel
                        className="show-in-break"
                        infiniteLoop
                        autoPlay={selectedItem === null}
                        emulateTouch
                        showStatus={false}
                        showArrows={false}
                        showThumbs={false}
                        onChange={(index) => (
                            selectedItem !== null && (toggleSelected(portfolioData[index]))
                        )}
                    >
                        {
                            portfolioData.map((item, index) => (
                                <PortfolioThumbs key={index} onClick={ () => toggleSelected(item) }>
                                    <div className="thumbnail">
                                        <img src={item.thumbnail} alt={item.name} />
                                    </div>
                                    <div className="title">
                                        <h5>0{ index + 1 }</h5>
                                        <h2>{ item.name }</h2>
                                    </div>
                                </PortfolioThumbs>
                            ))
                        }
                    </Carousel>
                </div>
                <PortfolioContent isSelected={!!selectedItem}>
                    <div className="btn-container" onClick={ () => toggleSelected(null) }>
                        <FaAngleDown/>
                    </div>
                    <div className="container">
                        {
                            !!selectedItem && (
                                <>
                                    <div className="description">
                                        <img src={selectedItem.logo} alt={selectedItem.name}/>
                                        <div className="services">
                                            <h5>Services</h5>
                                            {
                                                selectedItem.services.map((item, index) => (
                                                    <div key={index}>
                                                        <GoPrimitiveDot/><span>{item}</span>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div className="result">
                                            <h5>Result</h5>
                                            {
                                                selectedItem.result.map((item, index) => (
                                                    <div key={index}>
                                                        <span className="result-name">{item.name}</span>
                                                        <BsArrowRight />
                                                        <span>{item.data}</span>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className="image">
                                        <img style={selectedItem.style} src={selectedItem.content} alt="Portfolio"/>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </PortfolioContent>
            </div>
        </PortfolioContainer>
    )
}

export default PortfoliosSection
