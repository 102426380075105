import { css } from 'styled-components'
import { BREAK_POINT, Color } from './variables';

export function LightenDarkenColor(col, amt) {
  
    var usePound = false;
  
    if (col[0] === "#") {
        col = col.slice(1);
        usePound = true;
    }
 
    var num = parseInt(col,16);
 
    var r = (num >> 16) + amt;
 
    if (r > 255) r = 255;
    else if  (r < 0) r = 0;
 
    var b = ((num >> 8) & 0x00FF) + amt;
 
    if (b > 255) b = 255;
    else if  (b < 0) b = 0;
 
    var g = (num & 0x0000FF) + amt;
 
    if (g > 255) g = 255;
    else if (g < 0) g = 0;
 
    return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
  
}

export const OutlineHeading = (color) => css`
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 2.25px ${color};
    -webkit-background-clip: text;
`

export const fullScreenModalStyle = {
    content: {
        height: '100vh',
        width: '100vw',
        inset: '0px',
        background: Color.light,
        borderRadius: '0px',
        border: 'none',
        overflowX: 'hidden',
        padding: '0',
    }
}

export const HideInBreakPoint = css`
    @media only screen and (max-width: ${BREAK_POINT}) {
        display: none !important;
    }
`
