import im3Thumb from '../assets/portfolios/im3-thumbnail.jpg'
import im3Logo from '../assets/portfolios/im3-logo-content.png'
import im3Content from '../assets/portfolios/im3-content.png'
import giiasThumb from '../assets/portfolios/giias-thumbnail.jpg'
import giiasLogo from '../assets/portfolios/giias-logo-content.png'
import giiasContent from '../assets/portfolios/giias-content.png'
import telkomsigmaThumb from '../assets/portfolios/telkomsigma-thumbnail.jpg'
import telkomsigmaLogo from '../assets/portfolios/telkomsigma-logo-content.png'
import telkomsigmaContent from '../assets/portfolios/telkomsigma-content.png'
import mayoraThumb from '../assets/portfolios/mayora-thumbnail.jpg'
import mayoraLogo from '../assets/portfolios/mayora-logo-content.png'
import mayoraContent from '../assets/portfolios/mayora-content.png'
import amwayThumb from '../assets/portfolios/amway-thumbnail.jpg'
import amwayLogo from '../assets/portfolios/amway-logo-content.png'
import amwayContent from '../assets/portfolios/amway-content.png'
import tourismThumb from '../assets/portfolios/tourism-thumbnail.jpg'
import tourismLogo from '../assets/portfolios/tourism-logo-content.png'
import tourismContent from '../assets/portfolios/tourism-content.png'
import bekrafThumb from '../assets/portfolios/bekraf-thumbnail.jpg'
import bekrafLogo from '../assets/portfolios/bekraf-logo-content.png'
import bekrafContent from '../assets/portfolios/bekraf-content.png'
import koinworksThumb from '../assets/portfolios/koinworks-thumbnail.jpg'
import koinworksLogo from '../assets/portfolios/koinworks-logo-content.png'
import koinworksContent from '../assets/portfolios/koinworks-content.png'

const services = {
    sp: 'Strategic Planning',
    im: 'Influencer Marketing',
    ooa: 'Online to Offline Activation',
    smm: 'Social Mendia Marketing',
    wso: 'Website & SEO Optimization',
    cc: 'Content Creation',
    bsp: 'Branding & Strategic Planning',
}

const result = {
    c: 'Creators',
    e: 'Engagement',
    r: 'Reach',
    i: 'Impression',
    d: 'Deliverables',
    v: 'Views',
}

export const portfolioData = [
    {
        name: 'im3 ooredoo',
        thumbnail: im3Thumb,
        logo: im3Logo,
        content: im3Content,
        services: [
            services.sp,
            services.im,
            services.ooa,
        ],
        result: [
            {
                name: result.c,
                data: '41 Influencers'
            },
            {
                name: result.e,
                data: '> 1.4 Mio'
            },
            {
                name: result.r,
                data: '> 15.2 Mio'
            },
            {
                name: result.i,
                data: '> 18.4 Mio'
            },
            {
                name: 'UGC',
                data: '> 7.3k'
            },
        ],
    },
    {
        name: 'GIIAS',
        thumbnail: giiasThumb,
        logo: giiasLogo,
        content: giiasContent,
        services: [
            services.sp,
            services.im,
        ],
        result: [
            {
                name: result.c,
                data: '25 Influencers'
            },
            {
                name: result.d,
                data: '37 photos, 23 videos, 198 stories'
            },
            {
                name: result.e,
                data: '> 400k'
            },
            {
                name: result.r,
                data: '> 3.5 Mio'
            },
            {
                name: result.i,
                data: '> 4.7 Mio'
            }
        ],
    },
    {
        name: 'Telkomsigma',
        thumbnail: telkomsigmaThumb,
        logo: telkomsigmaLogo,
        content: telkomsigmaContent,
        services: [
            services.sp,
            services.smm,
            services.ooa,
            services.wso,
            services.cc
        ],
        result: [
            {
                name: result.e,
                data: '> 366k'
            },
            {
                name: result.r,
                data: '> 482k'
            },
            {
                name: result.i,
                data: '> 1.3 Mio'
            }
        ],
    },
    {
        name: 'Mayora',
        thumbnail: mayoraThumb,
        logo: mayoraLogo,
        content: mayoraContent,
        services: [
            services.im,
        ],
        result: [
            {
                name: result.c,
                data: '40 youtubers'
            },
            {
                name: result.d,
                data: '196 youtube videos'
            },
            {
                name: result.e,
                data: '> 250k'
            },
            {
                name: result.v,
                data: '> 6.1 mio'
            },
        ],
    },
    {
        name: 'Amway',
        thumbnail: amwayThumb,
        logo: amwayLogo,
        content: amwayContent,
        services: [
            services.cc,
        ],
        result: [
            {
                name: result.d,
                data: '100 website content products and ui revamp'
            },
        ],
    },
    {
        name: 'Tourism Authority of Thailand',
        thumbnail: tourismThumb,
        logo: tourismLogo,
        content: tourismContent,
        services: [
            services.sp,
            services.im,
        ],
        result: [
            {
                name: result.c,
                data: '26 Influencers'
            },
            {
                name: result.d,
                data: '26 Photos, 32 Stories'
            },
            {
                name: result.e,
                data: '> 140k'
            },
            {
                name: result.r,
                data: '> 1.1 Mio'
            },
            {
                name: result.i,
                data: '> 1.2 Mio'
            }
        ],
    },
    {
        name: 'BEKRAF',
        thumbnail: bekrafThumb,
        logo: bekrafLogo,
        content: bekrafContent,
        services: [
            services.bsp,
            services.cc,
            services.smm,
        ],
        result: [
            {
                name: result.e,
                data: '> 17k'
            },
            {
                name: result.r,
                data: '> 653k'
            },
            {
                name: result.i,
                data: '> 757k'
            }
        ],
    },
    {
        name: 'Koinworks',
        thumbnail: koinworksThumb,
        logo: koinworksLogo,
        content: koinworksContent,
        services: [
            services.sp,
            services.im,
            services.ooa,
        ],
        result: [
            {
                name: result.c,
                data: '209 Influencers'
            },
            {
                name: result.d,
                data: '211 Photos, 870 Stories'
            },
            {
                name: result.e,
                data: '> 130k'
            },
            {
                name: result.r,
                data: '> 1.07 Mio'
            },
            {
                name: result.i,
                data: '> 1.4 Mio'
            }
        ],
    },
]
