import React, {useEffect, useState} from 'react'
import TrackVisibility from 'react-on-screen';
import Footer from './components/Footer';
import Navbar, { NavAnchor, navZindex } from './components/Navbar'
import BackgroundSection from './components/BackgroundSection';
import AboutSection from './components/sections/AboutSection';
import ClientSection from './components/sections/ClientSection';
import HeroSection from './components/sections/HeroSection';
import TeamsSection from './components/sections/TeamSection';
import GlobalStyle from './styles/globalStyles';
import Modal from 'react-modal';
import ContactModal from './components/modals/ContactModal';
import { Color } from './styles/variables';
import PortfoliosSection from './components/sections/PortfoliosSection';
import FeaturesSection from './components/sections/FeaturesSection';
import { Routes, Route } from 'react-router-dom';

import ShortsNavbar from './shorts/Navbar';
import ShortsFooter from './shorts/Footer';
import ShortsHeroSection from './shorts/sections/HeroSection';
import ShortsPortfoliosSection from './shorts/sections/PortfoliosSection';
import ShortsFeaturesSection from './shorts/sections/FeaturesSection';


Modal.defaultStyles.overlay = {
    zIndex: navZindex + 10,
    position: 'fixed',
    inset: '0px',
    backgroundColor: Color.primary + '45',
}
Modal.setAppElement('#root')

function App() {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    const { hash } = window.location;
    if (hash) {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }, 100);
      }
    }
  }, []);

  return (
    <>
      <Routes>
        <Route path='/' element={
          <>
            <GlobalStyle />
            <Navbar toggle={toggle} scrolled={true} />
            <HeroSection />

            <TrackVisibility partialVisibility>
                <BackgroundSection text={'About Us'}/>
            </TrackVisibility>
            <NavAnchor id='about' />
            <AboutSection />

            <TrackVisibility partialVisibility>
                <BackgroundSection text={'Services'}/>
            </TrackVisibility>
            <NavAnchor id='services' />
            <FeaturesSection />

            <TrackVisibility partialVisibility>
                <BackgroundSection text={'Work'}/>
            </TrackVisibility>
            <NavAnchor id='work' />
            <PortfoliosSection />

            <TrackVisibility partialVisibility>
                <BackgroundSection text={'Client'}/>
            </TrackVisibility>
            <ClientSection />
            
            {/* <TrackVisibility partialVisibility>
                <BackgroundSection text={'Team'}/>
            </TrackVisibility>
            <NavAnchor id='team' />
            <TeamsSection /> */}
            <Footer toggle={toggle} />
            
            <ContactModal isOpen={isOpen} toggle={toggle}  />
          </>
        } />
        <Route path='/shorts' element={
            <>
              <GlobalStyle />
              <ShortsNavbar />
              <ShortsHeroSection />

              <ShortsPortfoliosSection />

              <ShortsFeaturesSection />
              
              <ShortsFooter />
            </>
        } />
      </Routes>
    </>
  );
}

export default App;
