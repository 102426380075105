import React from 'react'
import styled from 'styled-components'
import { BREAK_POINT, Color } from '../../styles/variables'

import scripting from '../../assets/features/scripting.jpg'
import talentProviding from '../../assets/features/talent-providing.jpg'
import shooting from '../../assets/features/shooting.jpg'
import editing from '../../assets/features/editing.jpg'

export const featureData = [
    {
        image: scripting,
        name: 'Scripting',
    },
    {
        image: talentProviding,
        name: 'Talent Providing',
    },
    {
        image: shooting,
        name: 'Shooting',
    },
    {
        image: editing,
        name: 'Editing',
    },
]


const FeatureContainer = styled.section`
    padding: 5rem 0;
    background-color: ${Color.primary};
    color: #ffffff;

    .heading-wrapper {
        text-align: center;
        margin-bottom: 3.5rem;

        h1 {
            font-size: 5rem;
            text-transform: uppercase;
        }
    }

    .features-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: -0.5rem 0;

        .item-wrapper {
            width: 50%;
        }

        .feature-item {
            margin: 0.5rem;
            border-radius: 1rem;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            overflow: hidden;

            color: ${Color.accent};
            
            .feature-overlay {
                background: rgba(255, 255, 255, 0.25);
                width: 100%;
                padding: 3.75rem 1rem;
            }

            h5 {
                font-size: 2.25rem;
                font-weight: 800;
            }
        }
    }

    @media only screen and (max-width: ${BREAK_POINT}) {

        .heading-wrapper {
            h1 {
                font-size: 3rem;
            }
        }

        .features-wrapper {

            .item-wrapper {
                width: 100%;
            }
        }
    }
`

const ShortsFeaturesSection = () => {

    return (
        <FeatureContainer>
            <div className='container'>
                <div className='heading-wrapper'>
                    <h1>What we do</h1>
                </div>
                <div className='features-wrapper'>
                {
                    featureData.map((item) => (
                        <div className='item-wrapper'>
                            <div className='feature-item' style={{ backgroundImage: `url(${item.image})` }}>
                                <div className='feature-overlay flex-center'>
                                    <h5>{item.name}</h5>
                                </div>
                            </div>
                        </div>
                    ))
                }
                </div>
            </div>
        </FeatureContainer>
    )
}

export default ShortsFeaturesSection
