import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { BREAK_POINT, Color } from '../../styles/variables'
import HeroBg from '../../assets/images/hero-background.jpg'
import { navHeight } from '../Navbar'
import logo from '../../assets/logo-full.svg'
import Typist from 'react-typist'
import Hero1 from '../../assets/images/hero-1.png'
import Hero2 from '../../assets/images/hero-2.png'
import Hero3 from '../../assets/images/hero-3.png'
import Hero4 from '../../assets/images/hero-4.png'
import Hero5 from '../../assets/images/hero-5.png'
import Hero6 from '../../assets/images/hero-6.png'
import Hero7 from '../../assets/images/hero-7.png'
import LazyLoad from 'react-lazyload'
import { LightenDarkenColor } from '../../styles/helperStyle'

const HeroContainer = styled.section`
    height: 100vh;
    max-height: 800px;
    /* background-image: url(${HeroBg});
    background-color: ${Color.primary};
    background-position: left center;
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: multiply; */
    color: ${Color.primary};

    .container {
        margin-top: ${ navHeight };
        display: flex;
        align-items: center;

        .content-1 {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            margin-bottom: 4rem;

            h3 {
                font-weight: 600;
                margin-bottom: 1px;
                margin-right: 0.5rem;
            }
            img {
                width: 190px;
                height: auto;
                object-fit: cover;
            }
        }

        .content-2 {
            text-transform: uppercase;
            margin-bottom: 6rem;
            /* white-space: nowrap; */

            .text-wrapper {
                width: 14em;
                max-width: 100%;
                display: inline-block;
            }

            .typing-wrapper {
                display: inline-block;
            }
            .typing-content {
                color: ${ Color.accent };
                font-weight: 900;
            }
        }

        .Cursor {
            color: ${ LightenDarkenColor(Color.accent, -20) };
            font-weight: 600;
        }

        .wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            transform: translateY(-20%);

            .content-wrapper {
                width: 60%
            }

            .image-wrapper {
                position: relative;
                width: 20rem;
                height: 20rem;
                max-width: 100%;

                img {
                    position: absolute;
                }

                img.hero-1 {
                    transform: translate(-107%, 5%);
                    width: 93%;
                    z-index: 1;
                }
                img.hero-2 {
                    transform: translate(-58%, 22%);
                    width: 74%;
                    z-index: 2;
                }
                img.hero-3 {
                    transform: translate(-87%, 202%);
                    width: 122%;
                    z-index: 3;
                }
                img.hero-4 {
                    transform: translate(25%, 148%);
                    width: 103%;
                    z-index: 5;
                }
                img.hero-5 {
                    transform: translate(-88%, 248%);
                    width: 100%;
                    z-index: 6;
                }
                img.hero-6 {
                    transform: translate(159%, 13%);
                    width: 50%;
                    z-index: 7;
                }
                img.hero-7 {
                    transform: translate(-2%, -10%);
                    width: 130%;
                    z-index: 4;
                }
            }
        }
    }

    @media only screen and (max-width: ${BREAK_POINT}) {
        min-height: 100vh;
        height: fit-content;
        max-height: unset;

        .container {
            align-items: flex-start;

            .wrapper {
                margin-top: 10rem;
                margin-bottom: 5rem;

                .content-wrapper {
                    width: 100%;

                    .content-1 {
                        margin-bottom: 1rem;
                    }
                    .content-2 {
                        .typing-wrapper {
                            display: block;
                        }
                    }
                }
                
                .image-wrapper {
                    width: 15rem;
                    height: 15rem;
                    margin-left: auto;
                }
            }

        }
    }
`

const HeroSection = () => {
    const [count, setCount] = useState(1);

    useEffect(() => {
        setCount(1);
    }, [count]);

    return (
        <HeroContainer>
            <div className="container">
                <div className="wrapper">
                    <div className="content-wrapper">
                        <div className="content-1">
                            <h3>Hi, we are </h3>
                            <img src={logo} alt="Virallo"/>
                        </div>
                        <div className="content-2">
                            <h2>
                                <div className="text-wrapper">
                                    We enjoy what&nbsp;
                                    <span style={{display: 'inline-block'}}>We do:&nbsp;</span>
                                    <div className="typing-wrapper">
                                        {
                                            count && 
                                                <Typist avgTypingDelay={100} onTypingDone={() => setCount(0)} cursor={{
                                                    show: true,
                                                    blink: true,
                                                    element: '|',
                                                    hideWhenDone: false,
                                                    hideWhenDoneDelay: 1000,
                                                }}>
                                                    <span className="typing-content">Planning</span>
                                                    <Typist.Backspace count={10} delay={1000} />
                                                    <span className="typing-content">Executing</span>
                                                    <Typist.Backspace count={10} delay={1000} />
                                                    <span className="typing-content">Optimizing</span>
                                                    <Typist.Backspace count={10} delay={1000} />
                                                </Typist>
                                        }
                                    </div>
                                </div>
                            </h2>
                        </div>
                    </div>
                    <div className="image-wrapper">
                        <LazyLoad height={325} offset={200} once>
                            <img className="hero-1" src={Hero1} alt="Hero 1"/>
                            <img className="hero-2" src={Hero2} alt="Hero 2"/>
                            <img className="hero-3" src={Hero3} alt="Hero 3"/>
                            <img className="hero-4" src={Hero4} alt="Hero 4"/>
                            <img className="hero-5" src={Hero5} alt="Hero 5"/>
                            <img className="hero-6" src={Hero6} alt="Hero 6"/>
                            <img className="hero-7" src={Hero7} alt="Hero 7"/>
                        </LazyLoad>
                    </div>
                </div>
            </div>
        </HeroContainer>
    )
}

export default HeroSection
