import React from 'react'
import styled, {css} from 'styled-components'
import { Link } from 'react-router-dom'
import { Color } from '../styles/variables'
import { LightenDarkenColor } from '../styles/helperStyle'

const BtnContainer = styled(Link)`
    display: block;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
    
    .btn-cta {
        transition: .3s;
        background: ${Color.accent};
        border: 1.75px solid ${Color.primary};
        z-index: 2;
        position: relative;
        top: -0.5em;
        left: -0.5em;
        padding: 0.5em 2em;

        ${
            ({size}) => ( size === 'big' ?
                css`
                    font-weight: 900;
                    font-size: 1.5rem;
                ` :
                css`
                    font-weight: 700;
                    font-size: 1.15rem;
                `
            )
        }
    }

    .btn-decoration {
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 100%;

        ${
            ({size}) => ( size === 'big' ?
                css`
                    background: ${Color.primary};
                    border: 2.5px solid ${Color.accent};
                ` :
                css`
                    background: ${Color.accent};
                    border: 1.75px solid ${Color.primary};
                `
            )
        }
    }

    &:hover {
        .btn-cta {
            transform: translate(0.25em, 0.25em);
            background: ${LightenDarkenColor(Color.accent, 15)};
        }
    }
    
    &:active {
        .btn-cta {
            transform: translate(0.45em, 0.45em);
            color: ${LightenDarkenColor(Color.primary, 15)};
        }
    }
`

const ButtonCTA = ({ to, size, content }) => {
    return (
        <BtnContainer to={to} size={size}>
            <div className="btn-decoration"></div>
            <div className="btn-cta">{content}</div>
        </BtnContainer>
    )
}

export default ButtonCTA

