import React from 'react'
import styled from 'styled-components'
import { BREAK_POINT, Color } from '../../styles/variables'
import { navHeight } from '../Navbar'
import video1 from '../../assets/videos/video-hero-1.mp4'
import video2 from '../../assets/videos/video-hero-2.mp4'

export const maxHeight = '800px'
export const videos =  [video1, video2];

const HeroContainer = styled.section`
    height: 100vh;
    max-height: ${maxHeight};
    color: ${Color.primary};

    .hero-background {
        height: 100%;
        max-height: ${maxHeight};
        width: 100%;
        position: absolute;
        top: 0;
        z-index: 0;

        .video-background {
            height: 100%;
            width: 50%;
            
            background-color: ${Color.primary};
        }
    }
    .hero-title {
        text-transform: uppercase;
        margin-bottom: 1rem;

        h4 {
            font-size: 2.5rem;
            font-weight: 600;
        }
        h1 {
            font-size: 8rem;
            font-weight: 900;
            line-height: 7rem;
            display: inline-flex;
        }

        .box-accent {
            width: 9.5rem;
            height: 5.5rem;
            background: ${Color.accent};
            display: inline-flex;
            margin-left: 1rem;
        }
    }

    .hero-subtitle {
        color: ${Color.dark};

        h5 {
            text-transform: uppercase;
            font-size: 2rem;
            font-weight: 600;
        }

        h6 {
            font-size: 2rem;
            font-weight: 400;
        }

        p {
            font-size: 1.5rem;
            font-weight: 400;
            margin-top: 2.75rem;
        }
    }

    .hero-btn {
        display: inline-block;
        border-radius: 1000px;
        border: none;
        padding: 0.5rem 1rem;
        margin: 2.5rem 0;
        background-color: ${Color.accent};
        color: ${Color.primary};
        font-size: 1.5rem;
        font-weight: 600;
        text-decoration: none;
        transition: .3s;
        cursor: pointer;

        &:hover {
            background-color: ${Color.primary};
            color: #fff;
        }
    }

    .container {
        padding-top: ${ navHeight };
        display: flex;
        align-items: center;
        position: relative;
        z-index: 1;

        .wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;

            .content-wrapper {
                width: 50%;
                padding: 2rem 0;
                padding-left: 3.25rem
            }

            .video-wrapper {
                width: 50%;
                padding: 2rem;
                padding-left: 0;
                margin-left: -0.5rem;
                margin-right: -0.5rem;
            }

            .video-item {
                height: 29.5rem;
                width: auto;
                margin: 0.5rem;
                border-radius: 1rem;
                overflow: hidden;
            }
        }

    }

    @media only screen and (max-width: ${BREAK_POINT}) {
        min-height: 100vh;
        height: fit-content;
        max-height: unset;

        .primary-background {
            width: 100%;
            background-color: ${Color.primary};
        }

        .container {
            /* align-items: flex-start; */

            .hero-title {
                h4 {
                    font-size: 2rem;
                }

                h1 {
                    font-size: 6rem;
                    line-height: 5rem;
                }

                .box-accent {
                    width: 9rem;
                    height: 4rem;
                }
            }
            
            .wrapper {
                flex-direction: column-reverse;

                .content-wrapper, .video-wrapper {
                    width: 100%;
                    padding-left: 0;
                    padding-right: 0;
                }

                .video-wrapper {
                    margin: -2rem 0 2rem;
                    .video-item {
                        height: 21.5rem;
                    }
                }

            }
        }
    }
`

const ShortsHeroSection = () => {

    return (
        <HeroContainer>
            <div className="hero-background hide-in-break">
                <div className="video-background"></div>
            </div>
            <div className="container">
                <div className="wrapper">
                    <div className="video-wrapper flex-center hide-in-break">
                        {
                            videos.map((item) => (
                                <video className="video-item" controls autoPlay={true} muted={true} loop={true}>
                                    <source src={item} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            ))
                        }
                    </div>
                    
                    <div className="content-wrapper">
                        <div className='hero-title'>
                            <h4>Making Your Brand</h4>
                            <h1>Stand</h1>
                            <h1>Out</h1>
                            <div className="box-accent"></div>
                        </div>
                        <div className='hero-subtitle'>
                            <h5>With Digital Content</h5>
                            <h6>Short Video Production</h6>

                            <p>We make your brand stand out with short video content production.</p>
                        </div>
                        
                        <a className='hero-btn' href='https://wa.me/message/7WRPDEK5NG6PN1' target="_blank" rel="noopener noreferrer">
                            Hit us up to get started!
                        </a>
                    </div>
                </div>
            </div>
            <div className="primary-background show-in-break">
                <div className="container">
                    <div className="wrapper">
                        <div className="video-wrapper flex-center">
                            {
                                videos.map((item) => (
                                    <video className="video-item" controls autoPlay={true} muted={true} loop={true}>
                                        <source src={item} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </HeroContainer>
    )
}

export default ShortsHeroSection
