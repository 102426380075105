import React from 'react'
import TrackVisibility from 'react-on-screen'
import styled from 'styled-components'
import { featureData } from '../../data/featureData'
import { LightenDarkenColor } from '../../styles/helperStyle'
import { BREAK_POINT, Color } from '../../styles/variables'
import { HeadingTitle } from '../HeadingTitle'

const FeatureContainer = styled.section`
    padding: 5rem 0;
    overflow: visible;

    .decoration-wrapper {
        height: 26rem;
        width: 0;
        position: sticky;
        top: 37vh;

        .circle-decoration {
            height: 26rem;
            width: 26rem;
            left: -18rem;
            position: absolute;
            border-radius: 1000px;
            border: 3px solid ${ LightenDarkenColor(Color.primary, 25) };
            top: -20%;
        }
    }
    
    .container {
        padding-bottom: 1rem;
        min-height: fit-content;

        .heading-wrapper {
            text-align: center;
        }

        .feature-wrapper {
            display: flex;

            .feature {
                display: flex;

                @media only screen and (max-width: ${BREAK_POINT}) {
                    text-align: center;
                }
            }

            .icon-container {
                width: 40%;
                /* position: relative; */

                .icon-wrapper {
                    height: 60vh;
                    /* position: sticky; */
                    display: flex;
                    align-items: center;
                    transition: .3s ease-in-out;

                    position: fixed;
                    width: 25%;
                    top: 20%;
                    right: 65%;

                    &.active {
                        img {
                            z-index: 1;
                            opacity: 1;
                            transform: translateY(0%);
                        }
                        .circle {
                            z-index: 1;
                            opacity: 1;
                            &.circle-1, &.circle-2 {
                                transform: translateX(0%);
                            }
                        }

                    }

                    img, .circle {
                        z-index: -1;
                        opacity: 0;
                        transition: .3s ease-in-out;
                    }

                    img {
                        width: 100%;
                        height: auto;
                        object-fit: cover;

                        transform: translateY(10%);
                    }

                    .circle {
                        width: 8rem;
                        height: 8rem;
                        border-radius: 1000px;
                        background-color: ${Color.primary};
                        position: absolute;
                        left: -10rem;

                        &.circle-1 { 
                            top: 0;
                            transform: translateX(30%);
                        }
                        &.circle-2 {
                            bottom: 0;
                            transform: translateX(-30%);
                        }
                    }
                    
                }
            }
            .content-container {
                width: 60%;

                @media only screen and (max-width: ${BREAK_POINT}) {
                    width: 100%;
                }
                
                .content-wrapper {
                    height: 70vh;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    @media only screen and (max-width: ${BREAK_POINT}) {
                        height: fit-content;
                        margin: 2rem 0;

                        img {
                            width: 14rem;
                            margin: 1rem auto 2rem;
                        }
                    }

                    
                    h1 {
                        color: ${Color.primary};
                        text-transform: uppercase;
                        margin-bottom: 2rem;
                        font-weight: 900;

                        @media only screen and (max-width: ${BREAK_POINT}) {
                            font-size: 2.5rem;
                        }
                    }

                    p.lead {
                        width: 90%;
                        margin: 0 auto;
                    }
                }
            }
        }
    }
`

const FeaturesSection = () => {
    // useEffect (()=>{
    //     document.addEventListener('scroll', e => {
    //         let element = document.getElementById('content-1').getBoundingClientRect();
    //         var body = document.body,
    //             html = document.documentElement;

    //         var height = Math.min( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );
    //         console.log(height, ' => ', html.clientHeight)
    //         if (element.top > 0 && element.bottom < height) {
    //             setShowIndex(1)
    //         }
    //     })
    // },[])

    return (
        <FeatureContainer>
            <div className="container">
                <div className="heading-wrapper">
                    <HeadingTitle>
                        <span>What</span>We Do
                    </HeadingTitle>
                </div>
                <div className="feature-wrapper">
                    <div className="decoration-wrapper hide-in-break">
                        <div className="circle-decoration"></div>
                    </div>
                    <div>
                        {
                            featureData.map((item, index) => (
                            <TrackVisibility key={index}>
                                {
                                    ({isVisible}) => 
                                        <div className="feature">
                                            <div className="icon-container hide-in-break">
                                                <div className={(isVisible ? 'icon-wrapper active' : 'icon-wrapper')}>
                                                    <div className="circle circle-1"></div>
                                                    <img src={item.icon} alt={item.name} />
                                                    <div className="circle circle-2"></div>
                                                </div>
                                            </div>
                                            <div className="content-container" id={'content-' + index}>
                                                <div className="content-wrapper">
                                                    <img className="show-in-break" src={item.icon} alt={item.name} />
                                                    <h1>{item.name}</h1>
                                                    <p className="lead">
                                                        {item.description}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </TrackVisibility>
                            ))
                        }
                    </div>
                </div>
            </div>
        </FeatureContainer>
    )
}

export default FeaturesSection
