import React from 'react'
import styled from 'styled-components'
import { socialData } from '../data/socialData'
import { BREAK_POINT, Color } from '../styles/variables'
import whatsapp from '../assets/images/whatsapp.png'
import { BiMap } from "react-icons/bi";

const FooterContainer = styled.section`
    /* background-color: ${ Color.primary }; */
    color: ${Color.primary};
    padding: 5.25rem 0 3.75rem;

    .container {

        .wa-btn {
            margin-right: 1rem;
            
            a img {
                width: 4rem;
                height: 4rem;
                transition: .3s;

                &:hover {
                    scale: 1.25;
                }
            }
        }

        .footer-wrapper {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -1rem;
        }

        .content-wrapper {
            width: 50%;
            padding: 0 1rem;
            display: flex;
        }

        h2 {
            font-weight: 700;
            font-size: 2.5rem;
            margin-bottom: 0.5rem;
        }

        h6 {
            font-size: 1.5rem;
            font-weight: 400;
        }

        .socials-wrapper {
            margin: 1.5rem 0 1.5rem -1rem;
            display: flex;
            align-items: center;
        }

        .address {
            width: 18rem;
            max-width: 100%;
        }

        .address-icon {
            font-size: 1.75rem;
            margin-right: 0.5rem;
        }

        p.copyright {
            font-weight: 400;
            text-align: center;
            margin: 6.25rem 0 1rem;
        }
    }
    
    @media only screen and (max-width: ${BREAK_POINT}) {
        .container {
            .footer-wrapper {
                margin: 0;
            }
            .content-wrapper {
                width: 100%;;
            }

            h2 {
                font-size: 2rem;
            }

            h6 {
                margin-bottom: 2rem;
            }
        }
    }
`

const SocialLinks = styled.a`
    height: 65px;
    width: 65px;
    border-radius: 1000px;
    background-color: ${Color.light};
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s;

    img {
        height: 60px;
        width: auto;
        object-fit: contain;
    }

    &:hover {
        opacity: .8;
    }

`

const shortsSocialData = socialData.filter(item => item.name !== 'twitter' && item.name !== 'tiktok')

const ShortsFooter = ({toggle}) => {
    return (
        <FooterContainer>
            <div className="container">
                <div className="footer-wrapper">
                    <div className="content-wrapper">
                        <div className='wa-btn'>
                            <a href="https://wa.me/message/7WRPDEK5NG6PN1" target="_blank" rel="noopener noreferrer">
                                <img src={whatsapp} alt="Whatsapp" />
                            </a>
                        </div>
                        <div>
                            <h2>Contact Us Now</h2>
                            <h6>
                                The Whatsapp icon on the left is not just for show, you know.
                                <br/>
                                Click it and have a chat with us!
                            </h6>
                        </div>
                    </div>
                    <div className="content-wrapper" style={{justifyContent: 'end'}}>
                        <div className='address-icon'>
                            <BiMap />
                        </div>
                        <div className='address'>
                            <p>
                                PT Seva Solusi Digital<br/>
                                Equity Tower Floor Suite 8B SCBD Lot 9, Jl. Jend Sudirman Kav. 52-53 Senayan, Kebayoran Baru, Jakarta Selatan 12190
                            </p>
                            <div className="socials-wrapper">
                                {
                                    shortsSocialData.map((item, index) => (
                                        <SocialLinks href={item.link} target='_blank' key={index} title={item.name}>
                                            <img src={item.icon} alt={item.name}/>
                                        </SocialLinks>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <p className='copyright'>&copy;{new Date().getFullYear()} PT Seva Solusi Digital</p>
            </div>
        </FooterContainer>
    )
}

export default ShortsFooter
