import React from 'react'
import styled from 'styled-components'
import { BREAK_POINT, Color } from '../../styles/variables'
import { HeadingTitle } from '../HeadingTitle'
import Marquee from 'react-fast-marquee'
import { clientData } from '../../data/clientData'
import { maxWidthContainer } from '../Navbar'

const ClientContainer = styled.section`
    padding: 5rem 0 7rem;

    .container {
        padding-bottom: 1rem;
        min-height: fit-content;

        .heading-wrapper {
            text-align: center;
            
            p.lead {
                color: ${ Color.primary };
                width: 365px;
                max-width: 90%;
                margin: 2rem auto;
            }
        }
    }

    .client-wrapper {
        display: flex;
        align-items: center;
        width: ${maxWidthContainer};
        max-width: 100%;
        margin: 0 auto;
    }
`

const ClientLogo = styled.img`
    max-width: 11.25rem;
    max-height: 5.625rem;
    object-fit: contain;
    object-position: center;
    margin: 2rem 3rem;

    @media only screen and (max-width: ${BREAK_POINT}) {
        max-width: 6rem;
        max-height: 4rem;
        margin: 1rem 1.5rem;
    }
`

const ClientSection = () => {
    const halfLength = Math.round(clientData.length / 2)
    const firstHalfData = [...clientData].splice(0, halfLength)
    const secondHalfData = [...clientData].splice(halfLength)

    return (
        <ClientContainer>
            <div className="container">
                <div className="heading-wrapper">
                    <HeadingTitle>
                        <span>Our</span>Client
                    </HeadingTitle>
                    <p className="lead">
                        We are proud to work with some of the well-known brands,
                    </p>
                </div>
            </div>
            <div className="client-wrapper">
                <Marquee speed={30} gradientColor={[244, 240, 255]} gradientWidth={100}>
                    {
                        [...firstHalfData, ...firstHalfData].map((item, index) => (
                            <ClientLogo key={index} src={item.logo} alt={item.index} />
                        ))
                    }
                </Marquee>
            </div>
            <div className="client-wrapper">
                <Marquee speed={30} gradientColor={[244, 240, 255]} gradientWidth={100}>
                    {
                        [...secondHalfData, ...secondHalfData].map((item, index) => (
                            <ClientLogo key={index} src={item.logo} alt={item.index} />
                        ))
                    }
                </Marquee>
            </div>
        </ClientContainer>
    )
}

export default ClientSection
