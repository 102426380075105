import IG from '../assets/socials/IG.svg'
import FB from '../assets/socials/FB.svg'
import TW from '../assets/socials/Twitter.svg'
import IN from '../assets/socials/Linkedin.svg'
import TK from '../assets/socials/tiktok.svg'

export const socialData = [
    {
        icon: IG,
        name: 'instagram',
        link: 'https://www.instagram.com/virallo.id/'
    },
    {
        icon: FB,
        name: 'facebook',
        link: 'https://www.facebook.com/virallo.official'
    },
    {
        icon: TW,
        name: 'twitter',
        link: 'https://twitter.com/virallo_id'
    },
    {
        icon: IN,
        name: 'linkedin',
        link: 'https://www.linkedin.com/company/virallo/'
    },
    {
        icon: TK,
        name: 'tiktok',
        link: 'https://www.tiktok.com/@virallo.id?lang=en'
    },
]
