import React from 'react'
import styled from 'styled-components'
import { Color } from '../styles/variables'

export const navHeight = '3.5rem'
export const navZindex = 100
export const maxWidthContainer = '1366px'

const Nav = styled.nav`
    height: ${navHeight};
    width: 100%;
    transition: .3s;
    position: absolute;
    top: 0;
    z-index: ${navZindex};

    background: ${Color.accent};
`

const ShortsNavbar = () => {
    return (
        <Nav>
        </Nav>
    )
}

export default ShortsNavbar
