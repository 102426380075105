import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from 'react-responsive-carousel'
import styled from 'styled-components'
// import { portfolioData } from '../../data/portfolioData'
import { BREAK_POINT, Color } from '../../styles/variables'
import { maxWidthContainer } from '../Navbar'

import video1 from '../../assets/videos/video-portfolio-1.mp4'
import video2 from '../../assets/videos/video-portfolio-2.mp4'
import video3 from '../../assets/videos/video-portfolio-3.mp4'
import video4 from '../../assets/videos/video-portfolio-4.mp4'
import video5 from '../../assets/videos/video-portfolio-5.mp4'

const videos = [
    video1,
    video2,
    video3,
    video4,
    video5
];

const PortfolioContainer = styled.section`
    padding: 5rem 0 10rem;

    .container {
        min-height: fit-content;
        padding-bottom: 2rem;

        .heading-wrapper {
            text-align: center;
            color: ${Color.dark};

            h1 {
                font-size: 5rem;
                text-transform: uppercase;
                color: ${Color.primary};
                font-weight: 800;
                margin-bottom: 0.5rem;
            }

            h5 {
                font-size: 2.25rem;
                font-weight: 600;
            }

            p {
                font-size: 1.5rem;
                font-weight: 400;
            }
        }
    }

    .content-container {
        width: ${maxWidthContainer};
        max-width: 100%;
        overflow: visible;
        margin: 0 auto;

        .carousel.carousel-slider {
            overflow: visible;

            .slider-wrapper {
                overflow: visible;
            }

            .control-dots {
                margin-bottom: -4rem;
                padding: 0 2rem;

                .dot {
                    height: 14px;
                    width: 14px;
                    background: ${Color.accent};
                    border-radius: 0;
                    box-shadow: none;
                    opacity: 1;
                    transition: .3s;

                    &.selected {
                        transform: scale(1.25);
                        background: ${Color.primary};
                    }
                }
            }
        }
    }

    .video-item {
        height: 29.5rem;
        width: auto;
        margin: 0.5rem;
        border-radius: 1rem;
        overflow: hidden;
    }

    @media only screen and (max-width: ${BREAK_POINT}) {
        overflow-y: visible;

        .container {
            .heading-wrapper {
                h1 {
                    font-size: 4rem;
                }
            }
        }
    }

`

const ShortsPortfoliosSection = () => {
    // const [selectedItem, setSelectedItem] = useState(null)

    // const toggleSelected = (item) => {
    //     setSelectedItem(item)
    // }

    return (
        <PortfolioContainer>
            <div className="container">
                <div className="heading-wrapper">
                    <h1>Our Portfolio</h1>
                    <h5>With Digital Content</h5>
                    <p>Leave video content production to the expert!</p>
                </div>
            </div>

            <div style={{ position: 'relative' }}>
                <div className="content-container">
                    <Carousel
                        className='hide-in-break'
                        infiniteLoop={true}
                        autoPlay={true}
                        emulateTouch
                        centerMode={true}
                        showStatus={false}
                        showArrows={false}
                        showThumbs={false}
                        centerSlidePercentage={20}
                    >
                        {
                            videos.map((item) => (
                                <div>
                                    <video className="video-item" controls autoPlay={true} muted={true} loop={true}>
                                        <source src={item} type="video/mp4" />
                                        Your browser does not support the video tag
                                    </video>
                                </div>
                            ))
                        }
                    </Carousel>
                    <Carousel
                        className='show-in-break'
                        infiniteLoop={true}
                        autoPlay={true}
                        emulateTouch
                        centerMode={true}
                        showStatus={false}
                        showArrows={false}
                        showThumbs={false}
                        centerSlidePercentage={60}
                    >
                        {
                            videos.map((item) => (
                                <div>
                                    <video className="video-item" controls autoPlay={true} muted={true} loop={true}>
                                        <source src={item} type="video/mp4" />
                                        Your browser does not support the video tag
                                    </video>
                                </div>
                            ))
                        }
                    </Carousel>
                </div>
            </div>
        </PortfolioContainer>
    )
}

export default ShortsPortfoliosSection
