import React from 'react'
import styled from 'styled-components'
import Marquee from 'react-fast-marquee'
import { Color } from '../styles/variables'
import { OutlineHeading } from '../styles/helperStyle'
import { maxWidthContainer } from './Navbar'

const DividerWindow = styled.div`
    width: 100%;
    height: 25vh;
    background: transparent;
`

const BackgroundContainer = styled.div`
    /* background-color: ${Color.primary}; */
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: -1;
    
    display: flex;
    align-items: center;
    justify-content: center;

    .divider-wrapper {
        background-color: ${Color.primary};
        width: ${maxWidthContainer};
        max-width: 100%;
    }

    h1 {
        ${ OutlineHeading('#fff') }
        text-transform: uppercase;
        font-size: 5rem;
        font-weight: 900;
        margin: 0 4rem;
        white-space: nowrap;
    }
`

const BackgroundSection = ({ text, length, isVisible }) => {
    return (
        <DividerWindow>
            <BackgroundContainer>
                <div className="divider-wrapper" style={{ display: isVisible ? 'block' : 'none' }}>
                    <Marquee direction='right' speed={200} gradientWidth={100} gradientColor={[60, 2, 103]}>
                        {
                            [...Array(length)].map((e, index) => (
                                <h1 key={index}>{text}</h1>
                            ))
                        }
                    </Marquee>
                </div>
            </BackgroundContainer>
        </DividerWindow>
    )
}

BackgroundSection.defaultProps = {
    text: 'Divider',
    length: 6
}

export default BackgroundSection
