import React from 'react'
import styled from 'styled-components'
import { socialData } from '../data/socialData'
import { Color } from '../styles/variables'
import ButtonCTA from './ButtonCTA'

const FooterContainer = styled.section`
    background-color: ${ Color.primary };
    padding: 3.75rem 0;

    .container {
        text-align: center;
        color: #fff;

        h3 {
            font-weight: 700;
            margin-bottom: 1.5rem; 
        }

        h2 {
            font-weight: 900;
            font-style: italic;
            text-transform: uppercase;
        }

        .btn-wrapper {
            width: 24rem;
            font-style: italic;
            margin: 4rem auto;
        }

        .socials-wrapper {
            width: 24rem;
            margin: 4rem auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        p {
            font-weight: 600;
            text-transform: uppercase;
        }
    }
`

const SocialLinks = styled.a`
    height: 65px;
    width: 65px;
    border-radius: 1000px;
    background-color: ${Color.light};
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s;

    img {
        height: 60px;
        width: auto;
        object-fit: contain;
    }

    &:hover {
        opacity: .8;
    }

`

const Footer = ({toggle}) => {
    return (
        <FooterContainer>
            <div className="container">
                <h3>Let's</h3>
                <h2>Collaborate to transform</h2>

                <div className="btn-wrapper" onClick={toggle}>
                    <ButtonCTA to='/' size='big' content='Get in touch' />
                </div>

                <div className="socials-wrapper">
                    {
                        socialData.map((item, index) => (
                            <SocialLinks href={item.link} target='_blank' key={index} title={item.name}>
                                <img src={item.icon} alt={item.name}/>
                            </SocialLinks>
                        ))
                    }
                </div>

                <p>&copy;{new Date().getFullYear()} PT Seva Solusi Digital</p>
            </div>
        </FooterContainer>
    )
}

export default Footer
