import { createGlobalStyle } from 'styled-components'
import { HideInBreakPoint } from './helperStyle'
import { BREAK_POINT, Color } from './variables'

const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    html, body {
        font-family: 'Montserrat', sans-serif;
        letter-spacing: 1.25px;
        font-size: 16px;

        @media only screen and (max-width: ${BREAK_POINT}) {
            font-size: 14px;
        }
    }

    body {
        background-color: ${Color.primary};
    }

    h1 { font-size: 3rem }
    h2 { font-size: 2.5rem }
    h3 { font-size: 2rem }
    h4 { font-size: 1.5rem }
    h5 { font-size: 1.25rem }
    h6 { font-size: 1rem }

    section {
        overflow: hidden;
        background-color: ${ Color.light };
        width: 100%;
    }

    p {
        letter-spacing: 0;
    }

    .small {
        font-size: 0.875rem;
    }

    .lead {
        font-size: 1.25rem;
        color: ${Color.grey};
        line-height: 1.35;
        font-weight: 500;
    }

    .container {
        width: 1190px;
        max-width: 90%;
        min-height: 320px;
        height: 100%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .flex-center {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ReactModal__Overlay {
        transform: translateY(-100%);
        transition: transform 600ms ease-in-out;
    }

    .ReactModal__Overlay--after-open{
        transform: translateY(0%);
    }

    .ReactModal__Overlay--before-close{
        transform: translateY(-100%);
    }

    .hide-in-break {
        ${ HideInBreakPoint }
    }

    .show-in-break {
        display: none;
        @media only screen and (max-width: ${BREAK_POINT}) {
            display: block;
        }
    }
`

export default GlobalStyle
