import Amway from '../assets/clients/amway.png'
import AnomaliCoffee from '../assets/clients/Anomali.png'
import Bekraf from '../assets/clients/BEKRAF.png'
import Cisco from '../assets/clients/Cisco.png'
import Collega from '../assets/clients/Collega.png'
import ColorfulBitung from '../assets/clients/Colorful Bitung.png'
import Kolega from '../assets/clients/Kolega.png'
import DanaCita from '../assets/clients/Dana Cita.png'
import DanaDidik from '../assets/clients/Dana Didik.png'
import DiamondWedding from '../assets/clients/Diamond Wedding.png'
import GarudaFood from '../assets/clients/Garudafood.png'
import Giias from '../assets/clients/GIIAS.png'
import Mayora from '../assets/clients/Mayora.png'
import Gesits from '../assets/clients/gesits.png'
import GtMan from '../assets/clients/GTMAN.png'
import Im3 from '../assets/clients/IM3-ooredoo.png'
import Iss from '../assets/clients/ISS.png'
import KoinWorks from '../assets/clients/KoinWorks.png'
import Wwf from '../assets/clients/wwf.png'
import Mamasuka from '../assets/clients/mamasuka.png'
import Negarawan from '../assets/clients/negarawan.png'
import Telkomsigma from '../assets/clients/Telkomsigma.png'
import Upnormal from '../assets/clients/Upnormal.png'
import AkgGames from '../assets/clients/AKG-GAMES.png'
import Danain from '../assets/clients/danain.png'
import Flou from '../assets/clients/flou.png'
import Gtee from '../assets/clients/gtee.png'
import Jagofon from '../assets/clients/jagofon.png'
import Kominfo from '../assets/clients/kominfo.png'
import Kopiko from '../assets/clients/kopiko.png'
import Leminerale from '../assets/clients/leminerale.png'
import P from '../assets/clients/P.png'
import Tat from '../assets/clients/TAT.png'
import Wow from '../assets/clients/WOW.png'

export const clientData = [
    {
        logo: AkgGames,
        name: 'AKG Games',
    },
    {
        logo: Danain,
        name: 'Danain',
    },
    {
        logo: Flou,
        name: 'Flou',
    },
    {
        logo: Gtee,
        name: 'GTEE',
    },
    {
        logo: Jagofon,
        name: 'Jagofon',
    },
    {
        logo: Kominfo,
        name: 'Kominfo',
    },
    {
        logo: Kopiko,
        name: 'Kopiko 78c',
    },
    {
        logo: Leminerale,
        name: 'Leminerale',
    },
    {
        logo: P,
        name: 'P',
    },
    {
        logo: Tat,
        name: 'Tourism Authority of Thailand',
    },
    {
        logo: Wow,
        name: 'World of Warcraft',
    },
    {
        logo: Amway,
        name: 'Amway',
    },
    {
        logo: AnomaliCoffee,
        name: 'Anomali Coffee',
    },
    {
        logo: Bekraf,
        name: 'BEKRAF (Badan Ekonomi Kreatif Indonesia)',
    },
    {
        logo: Cisco,
        name: 'Cisco',
    },
    {
        logo: Collega,
        name: 'Collega',
    },
    {
        logo: ColorfulBitung,
        name: 'Colorful Bitung',
    },
    {
        logo: Kolega,
        name: 'Kolega',
    },
    {
        logo: DanaCita,
        name: 'Dana Cita',
    },
    // {
    //     logo: DanaDidik,
    //     name: 'Dana Didik',
    // },
    {
        logo: DiamondWedding,
        name: 'Diamond Wedding',
    },
    {
        logo: GarudaFood,
        name: 'GarudaFood',
    },
    {
        logo: Giias,
        name: 'GIIAS (Gaikindo Indonesia International Auto Show)',
    },
    {
        logo: Mayora,
        name: 'Mayora',
    },
    {
        logo: Gesits,
        name: 'Gesits',
    },
    {
        logo: GtMan,
        name: 'GTMan',
    },
    {
        logo: Im3,
        name: 'im3 Ooredoo',
    },
    {
        logo: Iss,
        name: 'ISS (Indonesia Startup Summit)',
    },
    {
        logo: KoinWorks,
        name: 'KoinWorks',
    },
    {
        logo: Wwf,
        name: 'WWF (World Wide Fund for Nature)',
    },
    {
        logo: Mamasuka,
        name: 'Mamasuka',
    },
    // {
    //     logo: Negarawan,
    //     name: 'negarawan',
    // },
    {
        logo: Telkomsigma,
        name: 'Telkomsigma',
    },
    {
        logo: Upnormal,
        name: 'Upnormal',
    },
]