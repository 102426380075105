import React from 'react'
import styled, { css } from 'styled-components'
import { BREAK_POINT, Color } from '../styles/variables'
import LogoColor from '../assets/logo-color.svg'
import LogoWhite from '../assets/logo-white.svg'
import ButtonCTA from './ButtonCTA'
import { menuData } from '../data/menuData'
import { LightenDarkenColor } from '../styles/helperStyle'
import { HashLink, NavHashLink } from 'react-router-hash-link'

export const navHeight = '6rem'
export const navZindex = 100
export const maxWidthContainer = '1366px'

const Nav = styled.nav`
    height: ${navHeight};
    z-index: ${navZindex};
    position: fixed;
    width: 100%;
    transition: .3s;
    top: 0;

    ${({ darkBackground }) => (
        darkBackground ?
            css`
            border-bottom: 1px solid ${Color.light};
            background: transparent;
        ` :
            css`
            border-bottom: 1px solid ${Color.dark};
            background: ${Color.light};
        `
    )}

    .nav-container {
        width: ${maxWidthContainer};
        max-width: 100%;
        margin: 0 auto;
        padding: 1rem;
        display: flex;
        align-items: center;

        .nav-menu {
            /* flex: 1 1 auto; */
            width: 45rem;
            max-width: 90%;
            color: ${({ darkBackground }) => darkBackground ? Color.light : Color.primary};
            display: flex;
            align-items: center;
            justify-content: space-around;
        }
        .nav-button {
            /* flex: 0 1 auto; */
            width: fit-content;
            margin-left: auto;
        }
    }

    /* a.active {
        color: ${LightenDarkenColor(Color.accent, -20)};
        font-weight: 800;
    } */
`

const Logo = styled(HashLink)`
    cursor: pointer;
    img {
        width: 4.625rem;
        height: 4.625rem;
        object-fit: contain;
    }

    @media only screen and (max-width: ${BREAK_POINT}) {
        margin-left: -1rem;
    }
`

const NavLinks = styled(NavHashLink)`
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    text-transform: uppercase;
    margin: auto 1.5rem;
    font-weight: 600;
    transition: .3s;
    white-space: nowrap;

    &:hover {
        color: ${LightenDarkenColor(Color.accent, -20)};
    }
`

export const NavAnchor = styled.div`
    height: ${navHeight};
    position: absolute;
    margin-top: ${'-' + navHeight};
`

const Navbar = ({ scrolled, toggle }) => {
    return (
        <Nav darkBackground={!scrolled}>
            <div className='nav-container'>
                <Logo to='/#' smooth>
                    <img src={scrolled ? LogoColor : LogoWhite} alt='Virallo' />
                </Logo>
                <div className="nav-menu hide-in-break">
                    {menuData.map((item, index) => (
                        <NavLinks 
                            key={index}
                            to={item.link}
                            smooth
                            activeClassName='active'
                        >{item.title}</NavLinks>
                    ))}
                </div>
                <div className="nav-button" onClick={ toggle } >
                    <ButtonCTA to='/' content='Get In Touch' />
                </div>
            </div>
        </Nav>
    )
}

export default Navbar
