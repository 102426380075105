import React from 'react'
import Modal from 'react-modal'
import styled from 'styled-components'
import { fullScreenModalStyle, LightenDarkenColor, OutlineHeading } from '../../styles/helperStyle'
import { FaTimes } from 'react-icons/fa'
import Patterns from '../../assets/images/dots-pattern.png'
import { BREAK_POINT, Color } from '../../styles/variables'
import LogoColor from '../../assets/logo-color.svg'
import {ImWhatsapp} from 'react-icons/im'
import {BsEnvelope} from 'react-icons/bs'
import { maxWidthContainer } from '../Navbar'

const ContactContainer = styled.div`
    background-image: url(${ Patterns });
    background-position: center 98%;
    background-repeat: repeat-x;
    background-size: auto;
    height: 100%;

    .contact-container {
        height: 100%;
        width: ${maxWidthContainer};
        max-width: 100%;
        margin: 0 auto;
        position: relative;
    }

    img {
        position: absolute;
        top: 2rem;
        left: 2rem;
        width: 74px;
        height: 74px;
        object-fit: contain;
    }

    .close {
        position: absolute;
        top: 2rem;
        right: 2rem;
        cursor: pointer;
        font-size: 2.5rem;
        height: 1.5em;
        width: 1.5em;
        border-radius: 1000px;
        color: ${ Color.dark };
        transition: .3s;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            color: ${ LightenDarkenColor(Color.primary, 20) };
            background-color: ${ Color.dark + '32' }
        }
    }

    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: ${ Color.primary };

        .heading-wrapper {
            text-align: center;
            text-transform: uppercase;
            margin: 3rem 0;

            h1 {
                font-size: 4rem;
                font-weight: 900;
                span { 
                    display: block;
                    ${ OutlineHeading(Color.primary) }
                }

                @media only screen and (max-width: ${BREAK_POINT}) {
                    font-size: 2rem;
                }
            }
        }

        .contact-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 3rem 0 6rem;

            .contact-item {
                display: block;
                background-color: ${Color.primary};
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 14rem;
                width: 14rem;
                border-radius: 1000px;
                margin: 0 6rem;
                cursor: pointer;
                transition: .3s;
                font-size: 7rem;

                &:hover {
                    color: ${ Color.accent };
                    font-size: 6rem;
                }

                @media only screen and (max-width: ${BREAK_POINT}) {
                    height: 8rem;
                    width: 8rem;
                    margin: 0 2rem;
                    font-size: 4rem;

                    &:hover {
                        font-size: 3rem;
                    }
                }
            }
        }
    }
`

const ContactModal = ({ isOpen, toggle }) => {
    return (
        <Modal
            isOpen={isOpen}
            closeTimeoutMS={600}
            style={fullScreenModalStyle}
        >
            <ContactContainer>
                <div className="contact-container">
                    <img src={LogoColor} alt='Virallo' />
                    <div className="close" onClick={toggle}>
                        <FaTimes />
                    </div>
                    <div className="container">
                        <div className="heading-wrapper">
                            <h1>
                                <span>How Can We</span>
                                Help You ?
                            </h1>
                        </div>
                        <div className="contact-wrapper">
                            <a href="mailto:partnership@virallo.id" target="_blank" rel="noopener noreferrer">
                                <div className="contact-item">
                                    <BsEnvelope/>
                                </div>
                            </a>
                            <a href="https://wa.me/6282310010070" target="_blank" rel="noopener noreferrer">
                                <div className="contact-item">
                                    <ImWhatsapp/>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </ContactContainer>
        </Modal>
    )
}

export default ContactModal
