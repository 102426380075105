
export const Color = {
    primary: '#3C0267',
    secondary: '#3BB4CC',
    accent: '#FFC822',
    light: '#F4F0FF',
    grey: '#5F5D5D',
    dark: '#4B4A4A',
}

export const BREAK_POINT = '768px';
